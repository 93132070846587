<template>
  <div class="login">
    <img src="@/assets/img_09.png" alt="" class="logo" />
    <div class="bg"></div>
    <div class="form-wrapper">
      <el-form ref="form" :model="form" label-width="0px" :rules="rules">
        <el-form-item prop="mobile">
          <el-input v-model="form.mobile" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="tips-30">30天内免登录</p>
      <div class="login-btn" @click="submit">登录</div>
      <div class="info-wrapper">
        <p>没有账号？<span @click="goPage('/register')">前往注册</span></p>
        <span @click="goPage('/password')">忘记密码</span>
      </div>

      <div class="agreement-box">
        <el-checkbox v-model="checked" />
        <p>
          阅读并接收<span @click="goPage('/agreement')"
            >《和和数据传输平台用户协议》</span
          >
        </p>
      </div>
    </div>
    <div class="tips">
      <a href="https://beian.miit.gov.cn/">沪ICP备2022078906号</a>
      <span>Copyright © 2022 上海和和义齿有限公司</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        mobile: "",
        password: "",
      },
      checked: false,
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 12, message: "长度在 6 到 12 个字", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    goPage(name) {
      this.$router.push({
        path: name,
      });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.$api
              .login({
                phone: this.form.mobile,
                password: this.form.password,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$cookie.set("_token_", res.data.token, { expires: 30 });
                  this.$router.push({
                    path: "/",
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          } else {
            this.$message({
              showClose: true,
              message: "请勾选“阅读并接收和和数据传输平台用户协议”",
              type: "error",
              duration: 1000,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.login {
  .el-input--medium .el-input__inner {
    background: #d6d7d8;
    &::placeholder {
      color: #868e98;
    }
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #2d8cf0;
    border-color: #2d8cf0;
    border: 2px solid #2d8cf0 !important;
  }
  .el-checkbox__inner {
    background: #d6d7d8;
    border: 2px solid #cccdd0 !important;
  }
}
</style>
<style lang="scss" scoped>
.login {
  height: 100vh;
  .logo {
    position: fixed;
    width: 60px;
    height: 60px;
    z-index: 9;
    left: 20px;
    top: 20px;
    object-fit: contain;
  }
  .bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../assets/bg_01.jpg) no-repeat;
    background-size: cover;
  }
  .form-wrapper {
    background: #e8e8e8;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 330px;
    border-radius: 4px;
    padding: 50px 40px 0;
    .login-btn {
      line-height: 38px;
      text-align: center;
      background: #2d8cf0;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      border-radius: 2px;
      margin-bottom: 8px;
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      line-height: 22px;
      p {
        font-size: 12px;
        color: rgb(29, 33, 41);
        span {
          cursor: pointer;
          color: #2d8cf0;
        }
      }

      span {
        font-size: 12px;
        cursor: pointer;
        color: #2d8cf0;
      }
    }

    .agreement-box {
      height: 32px;
      display: flex;
      align-items: center;
      p {
        color: rgb(41, 41, 41);
        padding-left: 6px;
        font-size: 12px;
        span {
          color: #2d8cf0;
          cursor: pointer;
        }
      }
    }
  }
  .tips {
    position: fixed;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    color: #afafaf;
    font-size: 12px;
    a {
      font-size: 12px;
      color: #afafaf;
    }
    span {
      font-size: 12px;
      color: #afafaf;
      display: inline-block;
      padding-left: 8px;
    }
  }
  .tips-30 {
    color: #afafaf;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
}
</style>